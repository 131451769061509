import axios from 'axios'

const Actions = {
  triggers: [],

  init(element = document) {
    this.triggers = Array.from(element.querySelectorAll('[data-action]'))

    if (this.triggers.length) {
      this.triggers.forEach((trigger) => {
        const event = trigger.dataset.actionEvent

        if (event === 'load') {
          window.addEventListener(event, (ev) => this.handleEvent(ev, trigger))
        } else {
          trigger.addEventListener(event, (ev) => this.handleEvent(ev))
        }
      })
    }
  },

  handleEvent(ev, trigger) {
    if (ev) {
      if (!trigger) {
        trigger = ev.currentTarget
      }

      if (trigger.getAttribute('action') === '#') {
        ev.preventDefault()
        ev.stopPropagation()
      }
    }

    this.runActions(trigger, [{ data: trigger.dataset }])
  },

  runActions(trigger, actions) {
    const requests = []

    if (actions && actions.length) {
      // Enable loading indicator
      trigger.classList.add('is-loading')

      actions.forEach((action) => {
        const formData = new FormData(
          trigger.hasAttribute('action') ? trigger : document.createElement('form'),
        )

        // Extend formData with trigger data attributes
        this.extendFormData(formData, action.data)

        // Allows to suffix action name with unique identifier
        if (formData.has('action')) {
          const actionParts = formData.get('action').split('-')

          formData.set('action', actionParts[0])
          formData.set('salt', actionParts[1])
        }

        // Extend formData if action was triggered on select element
        if (trigger.hasAttribute('name')) {
          const option = trigger.options[trigger.selectedIndex]

          if (option && typeof option.value !== 'undefined') {
            action.data.value = option.dataset.value
            formData.append('value', option.value)
          }
        }

        // Assign callback function
        action.callback = action.callback || this.callbacks[formData.get('action')] || (() => {})

        // Assign new request
        if (trigger.dataset.actionUrl) {
          requests.push(axios.get(trigger.dataset.actionUrl, formData))
        } else {
          requests.push(axios.post(Optilift.ajax_url, formData))
        }
      })

      // Run concurrent action
      axios.all(requests).then(
        axios.spread((...args) => {
          setTimeout(() => {
            args.forEach((arg, index) => {
              actions[index].callback(trigger, arg.data, actions[index].data.action)
            })

            // Disable loading indicator
            trigger.classList.remove('is-loading')
          }, 250)
        }),
      )
    }
  },

  extendFormData(formData, data) {
    Object.keys(data).forEach((key) => {
      if (key === 'actionData') {
        this.extendFormData(formData, JSON.parse(data[key]))
      } else {
        formData.append(key, data[key])
      }
    })
  },

  callbacks: {
    load_posts: (trigger, { data, success }) => {
      if (data && success) {
        if (data.posts && data.posts.length > 0) {
          data.posts.forEach((post) => {
            trigger.parentNode.insertAdjacentHTML('beforebegin', post)
          })
        }

        if (data.finished) {
          if (data.message) {
            trigger.insertAdjacentHTML('beforebegin', `<p class="u-notice">${data.message}</p>`)

            trigger.remove()
          } else {
            trigger.parentNode.remove()
          }
        } else {
          trigger.setAttribute('data-page', parseInt(trigger.dataset.page, 10) + 1)
        }
      }
    },
  },
}

export default Actions
