const FormNotes = {
  init(element = document) {
    const notes = Array.from(element.querySelectorAll('[data-form-notes]'))

    notes.forEach((note) => {
      const form = note.closest('form')
      const fields = Array.from(form.querySelectorAll('input, textarea'))

      form.addEventListener('submit', () => {
        note.classList.add('is-visible')
      })

      fields.forEach((field) => {
        field.addEventListener('focus', () => {
          note.classList.add('is-visible')
        })
      })
    })
  },
}

export default FormNotes
