import throttle from 'lodash.throttle'
import scroller from './scroll'

const Events = {
  lastScroll: 0,
  heightCalculated: false,

  init() {
    window.onload = () => setTimeout(this.handleLoad(), 0)
    window.onresize = throttle(this.handleResize, 200)
    window.onscroll = throttle(this.handleScroll, 50)
  },

  handleLoad() {
    Events.calculatePlaceholderHeight()
    Events.calculateScrollbarWidth()
    Events.handleScrollDirection()
    // Events.fixAnchorLinks();
  },

  handleResize() {
    Events.calculatePlaceholderHeight()
    Events.calculateScrollbarWidth()
  },

  handleScroll() {
    Events.handleScrollDirection()
  },

  /* Private functions */
  calculatePlaceholderHeight() {
    const root = document.documentElement
    const objects = Array.from(document.querySelectorAll('[data-placehold-height]'))

    objects.forEach((object) => {
      const name = object.dataset.placeholdHeight
      root.style.setProperty(`--${name}-height`, `${object.clientHeight}px`)
      object.style.setProperty('position', 'fixed')
    })

    this.heightCalculated = true
  },

  calculateScrollbarWidth() {
    const root = document.documentElement
    root.style.setProperty('--scrollbar-width', `${window.innerWidth - root.clientWidth}px`)
  },

  handleScrollDirection() {
    if (!this.heightCalculated) {
      return
    }

    const scrollUp = 'is-scrolled-up'
    const scrollDown = 'is-scrolled-down'
    const currentScroll = window.pageYOffset
    const offset = 100
    const bodyClasses = document.documentElement.classList

    if (Optilift.autoScrolling) {
      bodyClasses.remove(scrollUp)
      bodyClasses.add(scrollDown)
      return
    }

    if (currentScroll <= offset) {
      bodyClasses.remove(...[scrollUp, scrollDown])
      return
    }

    if (currentScroll > this.lastScroll && !bodyClasses.contains(scrollDown)) {
      // down
      bodyClasses.remove(scrollUp)
      bodyClasses.add(scrollDown)
    } else if (currentScroll < this.lastScroll && bodyClasses.contains(scrollDown)) {
      // up
      bodyClasses.remove(scrollDown)
      bodyClasses.add(scrollUp)
    }

    this.lastScroll = currentScroll
  },

  fixAnchorLinks() {
    const { hash } = window.location

    if (hash) {
      setTimeout(() => scroller.scrollTo(hash), 500)
    }
  },
}

export default Events
