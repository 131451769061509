/*
  Project: VOCA
  Author: piotr@kulpinski.pl
 */

import panels from './utils/panels'
import ui from './utils/ui'

// Panels
panels.init()

// UI
ui.init()
