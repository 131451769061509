import Actions from '../modules/actions'
import Carousel from '../modules/carousel'
import Events from '../modules/events'
import Expander from '../modules/expander'
import FormNotes from '../modules/form-notes'
import Hubspot from '../modules/hubspot'
import InViewport from '../modules/in-viewport'
import Map from '../modules/map'
import Panel from '../modules/panel'
import Popup from '../modules/popup'
import Scroll from '../modules/scroll'
import Validate from '../modules/validate'
import VimeoPlayer from '../modules/vimeo-player'

const Ui = {
  init: (element) => {
    Panel.init(element)
    Expander.init(element)
    Scroll.init(element)
    Carousel.init(element)
    InViewport.init(element)
    VimeoPlayer.init(element)
    Map.init(element)
    FormNotes.init(element)
    Validate.init(element)
    Actions.init(element)
    Events.init(element)
    Popup(element)
    Hubspot(element)
  },
}

export default Ui
