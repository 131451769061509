import scroller from './scroll'

const Expander = {
  eventsBlocked: false,

  init(element = document) {
    const triggers = Array.from(element.querySelectorAll('[data-expand]'))

    if (triggers.length) {
      triggers.forEach((trigger) => {
        const type = trigger.dataset.expandType || 'click'

        if (type === 'click') {
          trigger.addEventListener('click', this.handleExpander.bind(this))
        }

        if (type === 'hover') {
          trigger.parentElement.addEventListener('mouseenter', () => {
            const selector = trigger.dataset.expand
            this.expandElement(document.querySelector(selector), false, true)
          })

          trigger.parentElement.addEventListener('mouseleave', () => {
            const selector = trigger.dataset.expand
            this.collapseElement(document.querySelector(selector), false, true)
          })
        }
      })

      window.addEventListener('load', this.preloadExpanders.bind(this), false)
    }
  },

  preloadExpanders() {
    const { hash } = window.location
    const groupTriggers = Array.from(
      document.querySelectorAll('[data-expand-group] [data-expand-id]'),
    )

    if (groupTriggers.length === 1 || hash) {
      const trigger =
        groupTriggers.length === 1
          ? groupTriggers[0].parentNode.querySelector('[data-expand]')
          : document.querySelector(
              `[data-expand="[data-expand-id='${hash.replace('#', '', 'g')}']"]`,
            )
      const target = document.querySelector(trigger ? trigger.dataset.expand : '')

      if (target) {
        this.expandElement(target, true, !hash.length)
      }
    }
  },

  handleExpander(trigger) {
    if (trigger instanceof Element) {
      const selector = trigger.dataset.expand

      // Toggle expanders
      this.toggleExpanders(selector)
    } else {
      const selector = trigger.currentTarget.dataset.expand
      trigger.preventDefault()

      // Toggle expanders
      this.toggleExpanders(selector, true)
    }
  },

  toggleExpanders(selector, blockEvents = false) {
    if (blockEvents && this.eventsBlocked) {
      return
    }

    const target = document.querySelector(selector || '')

    if (target) {
      const group = target.closest('[data-expand-group]')
      const isAnimated = target.closest('[data-expand-animate]')

      if (group) {
        this.closeExpanders(group, target)

        // group.classList.toggle('has-expanded', !target.classList.contains('is-expanded'));
      }

      if (target.classList.contains('is-expanded')) {
        this.collapseElement(target, isAnimated)
      } else {
        this.expandElement(target, isAnimated)
      }
    }

    if (blockEvents) {
      // Block the events
      this.eventsBlocked = true

      setTimeout(() => {
        // Unblock the events
        this.eventsBlocked = false
      }, 300)
    }
  },

  closeExpanders(group, except) {
    const triggers = Array.from(group.querySelectorAll('[data-expand]'))

    if (triggers.length) {
      triggers.forEach((trigger) => {
        const target = document.querySelector(trigger.dataset.expand)

        if (target && target !== except) {
          this.collapseElement(target, target.closest('[data-expand-animate]'))
        }
      })
    }
  },

  collapseElement(element, animate = false, disableHistory = false) {
    const elementParent = this.getParentElement(element)

    if (!element || !element.classList.contains('is-expanded')) {
      return
    }

    if (animate) {
      const sectionHeight = element.scrollHeight

      const elementTransition = element.style.transition
      element.style.transition = ''

      requestAnimationFrame(() => {
        element.style.height = `${sectionHeight}px`
        element.style.transition = elementTransition

        requestAnimationFrame(() => {
          element.style.height = `${0}px`
        })
      })
    }

    element.classList.remove('is-expanded')

    // Toggle parent expanded class
    if (elementParent) {
      elementParent.classList.remove('has-expanded')
    }

    // Update the history state
    if (!disableHistory && element.dataset.expandState != null) {
      window.history.pushState({}, '', ' ')
    }
  },

  expandElement(element, animate = false, disableHistory = false) {
    const elementParent = this.getParentElement(element)

    if (!element || element.classList.contains('is-expanded')) {
      return
    }

    if (animate) {
      const sectionHeight = element.scrollHeight

      const transitionCallback = () => {
        element.removeEventListener('transitionend', transitionCallback)
        element.style.height = 'auto'
      }

      element.style.height = `${sectionHeight}px`

      element.addEventListener('transitionend', transitionCallback)
    }

    element.classList.add('is-expanded')

    // Toggle parent expanded class
    if (elementParent) {
      elementParent.classList.add('has-expanded')
    }

    // Update the history state
    if (!disableHistory && element.dataset.expandState != null) {
      window.history.pushState({}, '', `#${element.dataset.expandId}`)

      // Scroll to the currently open element
      if (elementParent) {
        setTimeout(() => scroller.scrollTo(elementParent), 500)
      }
    }
  },

  getParentElement(element) {
    return element.dataset.expandId ? document.getElementById(element.dataset.expandId) : null
  },
}

export default Expander
