import { observeElementInViewport } from 'observe-element-in-viewport'

const InViewport = {
  init(element = document) {
    const triggers = Array.from(element.querySelectorAll('[data-in-viewport]'))

    if (triggers.length) {
      triggers.forEach((trigger) => {
        const callback = trigger.dataset.inViewport

        observeElementInViewport(trigger, this[callback].inHandler, this[callback].outHandler, {
          viewport: null,
          threshold: [50],
        })
      })
    }
  },

  autoplay: {
    // handler for when target is in viewport
    inHandler: (entry, unobserve, targetEl) => {
      targetEl.play()
    },

    // handler for when target is NOT in viewport
    outHandler: (entry, unobserve, targetEl) => {
      targetEl.pause()
    },
  },
}

export default InViewport
