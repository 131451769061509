import Player from '@vimeo/player'
import axios from 'axios'
import UI from '../utils/ui'

let contentElement = null
let isUpdating = false
let isAutoDisplayed = false

const getPopupStorage = () => {
  return window.localStorage.getItem(`${Optilift.sitename}-is-popup-closed`)
}

const setPopupStorage = () => {
  window.localStorage.setItem(`${Optilift.sitename}-is-popup-closed`, true)
}

export const getPopupContentElement = () => {
  return contentElement
}

export const setPopupContentElement = (element) => {
  contentElement = element
  return contentElement
}

export const setPopupContent = (content) => {
  const popupContent = getPopupContentElement()

  isUpdating = true

  setTimeout(() => {
    popupContent.innerHTML = content
    isUpdating = false
  }, 500)
}

export const clearPopup = () => {
  if (getPopupContentElement()) {
    setPopupContent('')

    // Set local storage
    setPopupStorage()
  }

  // Remove popup class
  document.body.classList.remove('has-popup-open')
}

export const playPopupVideo = (content = null) => {
  const popupContent = content || getPopupContentElement()
  const video = popupContent.querySelector('[data-popup-video]')

  if (video) {
    let player = video.querySelector('video')

    if (!player) {
      player = new Player(video, {
        url: video.dataset.videoUrl,
        width: 1920,
        height: 1080,
        title: false,
        portrait: false,
        byline: false,
        responsive: false,
      })
    }

    player.play()
  }

  // Set popup content layout type
  popupContent.parentNode.dataset.layout = video ? 'video' : 'content'
}

export const insertPopupTemplate = (template) => {
  // Set popup content
  const element = setPopupContentElement(document.querySelector('[data-popup-content]'))

  // Insert template
  element.innerHTML = template

  // Play videos if there are any
  playPopupVideo()

  // Reinit UI elements
  UI.init(element)

  // Add popup class
  document.body.classList.add('has-popup-open')

  // Hide loader
  document.body.classList.remove('is-loading')
}

const handlePopup = (ev, instance = null) => {
  ev.preventDefault()

  const trigger = ev.currentTarget
  const target = instance || trigger.dataset.popup

  if (isUpdating) {
    return
  }

  if (trigger && target) {
    // Show loader
    document.body.classList.add('is-loading')

    if (target === 'remote') {
      const targetURL = new URL(ev.currentTarget.href)

      // Set remote param
      targetURL.searchParams.set('remote', true)

      axios.get(targetURL).then((response) => insertPopupTemplate(response.data))
    } else {
      const element = instance ? target : document.querySelector(target)

      if (element) {
        insertPopupTemplate(element.innerHTML)
      }
    }
  } else {
    clearPopup()
  }
}

const initTriggers = (element, selector) => {
  const triggers = Array.from(element.querySelectorAll(selector))

  triggers.forEach((trigger) => {
    trigger.removeEventListener('click', handlePopup)
    trigger.addEventListener('click', handlePopup)
  })
}

const initAutoTriggers = (element, selector) => {
  const triggers = Array.from(element.querySelectorAll(selector))

  window.addEventListener('load', (ev) => {
    triggers.forEach((trigger) => {
      if ((trigger.dataset.popupAuto !== 'once' || !getPopupStorage()) && !isAutoDisplayed) {
        const delay = trigger.dataset.popupDelay

        if (!delay) {
          handlePopup(ev, trigger)
        } else {
          if (!Number.isNaN(delay)) {
            setTimeout(() => {
              if (!isAutoDisplayed) {
                handlePopup(ev, trigger)
                isAutoDisplayed = true
              }
            }, parseInt(delay, 10))
          }

          if (trigger.dataset.popupBlur) {
            const autoPopupInterval = setInterval(() => {
              if (!isAutoDisplayed && !document.hasFocus()) {
                handlePopup(ev, trigger)
                isAutoDisplayed = true

                // CLear interval
                clearInterval(autoPopupInterval)
              }
            }, 300)
          }
        }
      }
    })
  })
}

export default (element = document) => {
  initTriggers(element, '[data-popup]')
  initAutoTriggers(element, '[data-popup-auto]')

  document.addEventListener('panels:close', clearPopup)
  document.addEventListener('popup:close', clearPopup)
}
