import { Loader } from 'google-maps'
import pinIcon from '~assets/images/pin.svg'

const Map = {
  map: false,

  async init(element = document) {
    this.mapElement = element.querySelector('[data-map]')

    if (this.mapElement) {
      this.google = await new Loader(Optilift.google_api_key).load()

      const styleArray = [
        {
          elementType: 'geometry',
          stylers: [
            {
              color: '#212121',
            },
          ],
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575',
            },
          ],
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#212121',
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry',
          stylers: [
            {
              color: '#757575',
            },
          ],
        },
        {
          featureType: 'administrative.country',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e',
            },
          ],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#bdbdbd',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575',
            },
          ],
        },
        {
          featureType: 'poi.business',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#181818',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#1b1b1b',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#2c2c2c',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#8a8a8a',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [
            {
              color: '#373737',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              color: '#3c3c3c',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road.highway.controlled_access',
          elementType: 'geometry',
          stylers: [
            {
              color: '#4e4e4e',
            },
          ],
        },
        {
          featureType: 'road.local',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161',
            },
          ],
        },
        {
          featureType: 'transit',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#000000',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#3d3d3d',
            },
          ],
        },
      ]
      const options = {
        zoom: 15,
        scrollwheel: false,
        mapTypeControl: false,
        overviewMapControl: false,
        streetViewControl: false,
        styles: styleArray,
        mapTypeControlOptions: {
          mapTypeIds: [this.google.maps.MapTypeId.ROADMAP, 'voca'],
        },
      }

      this.map = new this.google.maps.Map(this.mapElement, options)

      const vocaMap = new this.google.maps.StyledMapType(styleArray, {})
      this.map.mapTypes.set('voca', vocaMap)
      this.map.setMapTypeId('voca')

      // Populate map with content
      this.populateMap()
    }
  },

  populateMap() {
    this.addresses = this.mapElement.dataset.mapAddresses

    if (this.addresses) {
      const bounds = new this.google.maps.LatLngBounds()

      const addresses = JSON.parse(this.addresses).filter((address) => address.lat && address.lng)

      const icon = {
        url: pinIcon,
        size: new this.google.maps.Size(32, 32),
        origin: new this.google.maps.Point(0, 0),
        anchor: new this.google.maps.Point(16, 16),
        scaledSize: new this.google.maps.Size(32, 32),
      }

      addresses.forEach((address) => {
        const position = {
          lat: parseFloat(address.lat),
          lng: parseFloat(address.lng),
        }

        const marker = new this.google.maps.Marker({
          map: this.map,
          position,
          icon,
        })

        bounds.extend(position)

        if (addresses.length === 1) {
          this.map.setZoom(address.zoom || 12)
          this.map.setCenter(position)
        }

        this.google.maps.event.addListener(marker, 'click', () => {
          this.map.setZoom(address.zoom || 12)
          this.map.setCenter(position)
        })
      })

      if (addresses.length > 1) {
        this.map.fitBounds(bounds)
      }
    }
  },
}

export default Map
