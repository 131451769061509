import { clearPopup, insertPopupTemplate } from './popup'

const startObservable = (container) => {
  const observerConfig = {
    attributes: true,
    childList: true,
    characterData: true,
  }

  return new Promise((resolve) => {
    const observer = new MutationObserver((mutations) => {
      resolve(mutations)
    })

    observer.observe(container, observerConfig)
  })
}

const waitForMutation = async (container) => {
  try {
    return await startObservable(container)
  } catch (err) {
    console.error(err)
  }

  return null
}

const createForm = (form) => {
  if (!form || !hbspt || !jQuery) {
    return false
  }

  const formId = form.dataset.hubspotFormId

  // Clean up the form
  form.innerHTML = ''

  // Add the form
  hbspt.forms.create({
    portalId: Optilift.hubspot.portalId,
    formId,
    region: 'na1',
    version: 'V2_PRERELEASE',
    target: `[data-hubspot-form-id="${formId}"]`,
    translations: {
      en: {
        required: 'This is required.',
      },
    },

    onFormReady: ($form) => {
      const formData = $form.parent()[0].dataset

      // Don't apply this to the newsletter form
      if (formData.hubspotFormId === Optilift.hubspot.newsletter) {
        return
      }

      $form.addClass('u-loader')
      const $inputs = $form.find('input, textarea, select')
      $inputs.first().focus()

      function moveLabelAfterInput() {
        const $field = jQuery(this)
        const $input = $field.find('input, textarea, select')
        const $label = $field.find('label').hide().clone()

        $label.insertAfter($input.first()).show()
      }

      $form.find('.hs-form-field').each(moveLabelAfterInput)
    },

    onFormSubmit: ($form) => {
      $form.addClass('is-loading')
    },

    onFormSubmitted: ($form) => {
      const formParent = $form.parent()[0]
      const formData = formParent.dataset

      // Apply this to the restricted form only
      if (formData.hubspotFormId === Optilift.hubspot.restricted) {
        const { url } = formData

        if (url) {
          if (url.includes('vimeo')) {
            insertPopupTemplate(`<div data-video-url="${url}" data-popup-video></div>`)
          } else {
            // Close current popup
            clearPopup()

            // Open URL in the new window
            const win = window.open(url, '_blank')

            if (win === null || typeof win === 'undefined') {
              window.location.href = url
            } else {
              win.focus()
            }
          }
        }
      }
    },
  })

  return null
}

export default (element = document) => {
  const selector = '[data-hubspot-form-id]'
  const hsForms = Array.from(element.querySelectorAll(selector))

  if (hsForms.length) {
    hsForms.forEach((form) => {
      createForm(form)
    })
  }

  waitForMutation(element)
}
