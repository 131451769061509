const VimeoPlayer = {
  init(element = document) {
    this.triggers = Array.from(element.querySelectorAll('[data-vimeo-player]'))

    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        this.calculateVimeoPlayerSize(entry.target)
      })
    })

    this.triggers.forEach((player) => {
      observer.observe(player)
      this.calculateVimeoPlayerSize(player)
    })
  },

  calculateVimeoPlayerSize(player) {
    const video = player.querySelector('iframe')
    const width = player.offsetWidth
    const height = player.offsetHeight
    const videoRatio = 9 / 16
    const playerRatio = height / width

    if (playerRatio > videoRatio) {
      video.style.height = '100%'
      video.style.width = `${(playerRatio / videoRatio) * 100}%`
    } else {
      video.style.height = `${(videoRatio / playerRatio) * 100}%`
      video.style.width = '100%'
    }
  },
}

export default VimeoPlayer
