import Parsley from 'parsleyjs'

const Validate = {
  init(element = document) {
    const forms = Array.from(element.querySelectorAll('form[action]'))
    forms.forEach((form) => this.validate(form))
  },

  validate(form) {
    new Parsley.Factory(form)
  },
}

export default Validate
