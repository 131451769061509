import Flickity from 'flickity'

const Carousel = {
  init(element = document) {
    const carousels = Array.from(element.querySelectorAll('[data-carousel]'))

    carousels.forEach((carousel) => {
      setTimeout(() => {
        new Flickity(carousel, {
          contain: true,
          cellAlign: 'left',
          pageDots: false,
          adaptiveHeight: true,
          arrowShape:
            'M39.996 9 .202 48.794 39.996 88.59l2.946-2.946L8.18 50.877H100V46.71H8.179l34.763-34.764L39.996 9Z',
        })
      })
    })
  },
}

export default Carousel
